<template>
  <div class="church-details template-2">
    <header class="page-header container mx-lg d-flex">
      <h1>{{ translations.tcGideonCardProfile }}</h1>
      <QuickJump @facility_changed="handleFacilityChange()" :i18n="translations.components"></QuickJump>
    </header>
    <div class="page-body container mx-lg">
      <div class="details">
        <div class="d-flex row primary">
          <div class="col">
            <h4>{{ profileSummary && profileSummary.org_name }}</h4>
            <div class="info">
              <p v-if="facilityAddress" class="mb-0">
                {{ facilityAddress }}
              </p>
              {{ cityStateZipDisplay }}
              <p>
                <br />
                {{ profileSummary.phone_number_formatted }}
                <span v-if="profileSummary.email">|</span>
                <a :href="'mailto:' + profileSummary.email">{{ profileSummary.email }}</a>
              </p>
              <img v-if="!!profileSummary.image_url && profileSummary.image_url !== ''" :src="profileSummary.image_url"
                class="profile_image" />
              <camera v-if="!profileSummary.image_url || profileSummary.image_url === ''"></camera>
              <div v-if="iCanSee(secured_gideoncard_facility_edit_controls.edit_gideoncard_facility_button)">
                <div v-if="showUpload">
                  <b-form-file class="mb-1 mt-1" style="text-transform: uppercase" ref="fileUploadImg"
                    v-model.lazy="individual_image_file" name="individual_image"
                    :placeholder="translations.tcImageOptional" :drop-placeholder="translations.tcDropFileHere"
                    :browse-text="`${translations.tcBrowse}`" />
                  <button @click="onUpload" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcUploadPhoto }}
                  </button>
                  <button @click="onCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </button>
                </div>
                <div>
                  <b-link @click="showUpload = true" v-if="!showUpload">
                    <span v-if="!profileSummary.image_url">{{ translations.tcAdd }}</span>
                    <span v-if="profileSummary.image_url">{{ translations.tcUpdate }}</span> {{ translations.tcPhoto }}
                  </b-link>
                  <b-link @click="onDelete" v-if="profileSummary.image_url && !showUpload" class="ml-2">{{
                    translations.tcDeletePhoto
                  }}</b-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <h4>{{ translations.tcGideonCardDetails }}</h4>
            <div class="specs">
              <p>
                <strong>{{ translations.tcFacilityType }}:</strong>
                {{ facilityType }}
              </p>
              <p></p>
              <p>
                <strong>{{ translations.tcGideonCardDisplays }}:</strong>
                {{ profileSummary && profileSummary.gideoncard_display_number }}
              </p>
              <router-link :to="{ name: 'card_details' }" class="btn btn-primary">{{
                translations.tcViewMore
              }}</router-link>
            </div>
          </div>
          <div class="col">
            <h4>{{ translations.tcPrimaryContact }}</h4>
            <div class="contact">
              <h5>
                <a @click.prevent="handlePrimaryContactRedirect()" href="#">{{
                  profileSummary && profileSummary.contact_name
                }}</a>
              </h5>
              <a href="#">{{ profileSummary && profileSummary.contact_email }}</a>
              <br />
              <span>{{ profileSummary && profileSummary.contact_phone }}</span>
              <p>
                <strong>{{ translations.tcRelationship }}:</strong>
                {{ contactRelationship }}
              </p>
            </div>
          </div>
        </div>
        <div class="row d-flex events" v-if="showEvents">
          <div class="col col-4">
            <app-event :obj="lastRestockingVisitPresentation" :i18n="translations.components"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="lastVideoPresentation" :i18n="translations.components"></app-event>
          </div>
        </div>
        <div class="d-flex">
          <a @click.prevent="downloadReport(facilityDetailPDFUrl, 'FacilityDetailReport', 'pdf')" target="_blank"
            class="btn btn-primary mr-4">
            {{ translations.tcRunDetailedFacilityReport }}
          </a>
          <a v-if="iCanSee(secured_gideoncard_visit_add_controls.add_gideoncard_restocking_visit_button)"
            class="btn btn-primary mr-4" href="#" @click.prevent="handleAddVisitClick">{{
              translations.tcAddRestockingVisit
            }}</a>
          <a v-if="iCanSee(secured_gideoncard_visit_add_controls.add_gideoncard_video_presentation_button)"
            class="btn btn-primary" @click.prevent="handleAddPresentationClick" href="#">{{
              translations.tcAddVideoPresentation
            }}</a>
        </div>
      </div>
      <div class="actions">
        <div class="card-deck row">
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '4px' }"></div>
            <svg-contact-information></svg-contact-information>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcGideonCardCommunicationTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcGideonCardCommunicationDescription }}
              </p>
              <b-button to="/programs/gc/profile/card_communication" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '5px' }"></div>
            <svg-church-contacts></svg-church-contacts>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcGideonCardContactsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcGideonCardContactsDescription }}
              </p>
              <b-button to="/programs/gc/profile/card_contacts" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '0' }"></div>
            <svg-church-details></svg-church-details>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcGideonCardDetails }}</h5>
              <p class="card-text text-left">
                {{ translations.tcGideonCardDetailsDescription }}
              </p>
              <b-button to="/programs/gc/profile/card_details" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="card-deck row">
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '11px' }"></div>
            <svg-pres-service-times></svg-pres-service-times>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcVideoPresentationTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcVideoPresentationDescription }}
              </p>
              <b-button to="/programs/gc/profile/card-video-visits-list" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '0' }"></div>
            <svg-visits></svg-visits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcRestockingVisitTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcRestockingVisitDescription }}
              </p>
              <b-button to="/programs/gc/profile/card_restocking_visits_list" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '15px' }"></div>
            <svg-visits></svg-visits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcVisitsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcVisitsDescription }}
              </p>
              <b-button to="/programs/gc/profile/card_notes" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'
import ChurchContacts from '@/assets/svgs/church-contacts.vue'
import ChurchDetails from '@/assets/svgs/church-details.vue'
import ChurchMembersFriends from '@/assets/svgs/church-members-friends.vue'
import ContactInformation from '@/assets/svgs/contact-information.vue'
import dateData from '@/json/date.json'
import Event from '@/components/Event.vue'
import PresServiceTimes from '@/assets/svgs/presentations-service-times.vue'
import QuickJump from '@/components/quick-jump/QuickJumpGideonCards.vue'
import { translationMixin } from '@/mixins/translationMixin'
import Visits from '@/assets/svgs/visits.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'card_profile',
  mixins: [translationMixin, reportDownloadMixin],
  data() {
    return {
      contactRelationship: '',
      facilityType: '',
      translations: {},
      individual_image_file: null,
      showEvents: false,
      showUpload: false,
      lastRestockingVisitPresentation: {
        month: '',
        day: '',
        year: '',
        fullDate: '',
        title: 'Last Restocking Visit', // 'Last Restocking Visit'
        notes: 'No previous visits are recorded',
        speaker: '',
        meeting: null,
        facility: null,
        link: undefined,
      },
      lastVideoPresentation: {
        month: '',
        day: '',
        year: '',
        fullDate: '',
        title: 'Next Video Presentation', // 'Last Video Presentation'
        notes: 'No presentation date set',
        speaker: '',
        meeting: null,
        facility: null,
        link: undefined,
      },
      secured_gideoncard_visit_add_controls: {
        add_gideoncard_restocking_visit_button: '7fdd519a-35d1-4eca-b374-e96e8f05efbe',
        add_gideoncard_video_presentation_button: 'ed32320b-3333-444d-9f80-038c2808c769',
      },
      secured_gideoncard_facility_edit_controls: {
        edit_gideoncard_facility_button: '9407664b-79cf-4a0e-837d-a9fb27d56714',
      },
    }
  },
  components: {
    appEvent: Event,
    camera: camera,
    QuickJump: QuickJump,
    svgChurchContacts: ChurchContacts,
    svgChurchDetails: ChurchDetails,
    svgChurchMembersFriends: ChurchMembersFriends,
    svgContactInformation: ContactInformation,
    svgPresServiceTimes: PresServiceTimes,
    svgVisits: Visits,
  },
  methods: {
    ...mapActions({
      clearRestockingVisitDetails: 'card/clearRestockingVisitDetails',
      clearVideoVisitDetails: 'card/clearVideoVisitDetails',
      getFacilityDetailReportUrls: 'card/getFacilityDetailReportUrls',
      getFacilityRestockingVisits: 'card/getFacilityRestockingVisits',
      getFacilityVideoVisits: 'card/getFacilityVideoVisits',
      getFacilityVideoVisits: 'card/getFacilityVideoVisits',
      loadProfile: 'card/getProfile',
      saveChurchPhoto: 'churchMinistry/savePhoto',
      saveLocationPhoto: 'scriptureDistribution/savePhoto',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async pageLoad() {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.loadProfile(this.userSelectedFacilityKey),
        await this.getFacilityVideoVisits(this.userSelectedFacilityKey),
        await this.getFacilityRestockingVisits(this.userSelectedFacilityKey),
        await this.getFacilityDetailReportUrls(this.userSelectedFacilityKey),
      ]).then(() => {
        this.setLastRestockingPresentation(
          this.lastFacilityRestockingVisit,
          this.lastRestockingVisitPresentation,
          `/programs/gc/profile/restocking-visit-details`
        )
        this.setVideoPresentation(this.lastVideoList, this.lastVideoPresentation, `/programs/gc/profile/video-visit`)
        this.setDynamicText()
        this.showEvents = true
        this.setLoadingStatus(false)
      })
    },
    async handleAddVisitClick() {
      this.setSelectedVisitKey(null)
      this.clearRestockingVisitDetails()
      this.$router.push('/programs/gc/events/card_restocking_visit_add')
    },
    async handleAddPresentationClick() {
      this.setSelectedVisitKey(null)
      this.clearVideoVisitDetails()
      this.$router.push('/programs/gc/events/card_video_presentation_add')
    },
    async handleFacilityChange() {
      this.pageLoad()
    },
    async handlePrimaryContactRedirect() {
      await this.setSelectedIndividualKey(this.profileSummary.org_ind_key)
      this.$router.push({ name: 'non-member-profile-gc' })
    },
    async onUpload() {
      if (this.individual_image_file === null) {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcImageRequiredMessage,
        }).then((result) => {
          return
        })
        return
      } else {
        var payload = {
          iim_org_key: this.userSelectedFacilityKey,
          iim_image: null,
          iim_user: this.userIndKey,
          iim_delete_flag: false,
        }

        // Use the appropriate photo upload process based on whether or not the facility is a church
        // (all other facility types can be assumed as locations).
        if (this.profileSummary.org_type_name === 'Church') {
          await (payload.iim_image = this.individual_image_file)
          await Promise.all([this.setLoadingStatus(true), await this.saveChurchPhoto(payload)]).then((result) => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result[1] ? 'success' : 'error',
              confirmButtonText: this.translations.tcOk,
              text: result[1] ? this.translations.tcSaveChurchPhotoSuccess : this.translations.tcSaveChurchPhotoFailure,
            }).then((result) => {
              if (result.value) {
                this.pageLoad()
                this.showUpload = false
              }
            })
          })
        } else {
          await (payload.iim_image = this.individual_image_file)
          await Promise.all([this.setLoadingStatus(true), await this.saveLocationPhoto(payload)]).then((result) => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result[1] ? 'success' : 'error',
              confirmButtonText: this.translations.tcOk,
              text: result[1] ? this.translations.tcSaveChurchPhotoSuccess : this.translations.tcSaveChurchPhotoFailure,
            }).then((result) => {
              if (result.value) {
                this.pageLoad()
                this.showUpload = false
              }
            })
          })
        }
      }
    },
    onCancel() {
      this.individual_image_file = null
      this.showUpload = false
    },
    async onDelete() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        text: this.translations.tcDeletePhotoConfirmation,
      }).then((result) => {
        if (result.value) {
          this.deleteThePhoto()
        }
      })
    },
    async deleteThePhoto() {
      var payload = {
        iim_org_key: this.userSelectedFacilityKey,
        iim_user: this.userIndKey,
        iim_delete_flag: true,
      }

      // The photo is tied either to the organization's church or location, based
      // on the facility type (all facility types other than church are locations).
      if (this.profileSummary.org_type_name === 'Church') {
        await Promise.all([this.setLoadingStatus(true), await this.saveChurchPhoto(payload)]).then((result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            confirmButtonText: this.translations.tcOk,
            text: result ? this.translations.tcDeleteChurchPhotoSuccess : this.translations.tcDeleteChurchPhotoFailure,
          }).then((result) => {
            if (result.value) {
              this.pageLoad()
              this.showUpload = false
            }
          })
        })
      } else {
        await Promise.all([this.setLoadingStatus(true), await this.saveLocationPhoto(payload)]).then((result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            confirmButtonText: this.translations.tcOk,
            text: result ? this.translations.tcDeleteChurchPhotoSuccess : this.translations.tcDeleteChurchPhotoFailure,
          }).then((result) => {
            if (result.value) {
              this.pageLoad()
              this.showUpload = false
            }
          })
        })
      }
    },
    setDynamicText() {
      if (!this.translations || !this.profileSummary) return false

      // Relationship Type
      const relationshipType = this.profileSummary.contact_relationship
      this.contactRelationship = relationshipType
        ? this.textLookup(relationshipType, 'individual-relationship-type', 'commonText')
        : ''

      // Facility Type
      const facilityType = this.profileSummary.org_type_name
      this.facilityType = facilityType ? this.textLookup(facilityType, 'placement-facility-types', 'commonText') : ''

      return true
    },
    setLastRestockingPresentation(visit, pres, link) {
      if (!visit) {
        return
      }
      let today = new Date()
      let presentationDate = new Date(visit.presentation_date)
      pres.notes = undefined
      pres.year = presentationDate.getUTCFullYear()
      pres.day = presentationDate.getDate()
      pres.month = dateData.months.find((mi) => parseInt(mi.value) === presentationDate.getMonth() + 1).abbr
      pres.fullDate = visit.presentation_date
      pres.link = link
      pres.meeting = visit.mtg_key
      pres.title =
        presentationDate > today ? this.translations.tcNextRestockingVisit : this.translations.tcLastRestockingVisit
    },
    setVideoPresentation(visit, pres, link) {
      if (!visit) {
        return
      }
      pres.notes = undefined
      let today = new Date()
      let presentationDate = new Date(visit.presentation_date)
      pres.title =
        presentationDate > new Date()
          ? this.translations.tcNextVideoPresentation
          : this.translations.tcLastVideoPresentation
      pres.speaker = visit.presenter_name
      pres.year = presentationDate.getUTCFullYear()
      pres.day = presentationDate.getDate()
      pres.month = dateData.months.find((mi) => parseInt(mi.value) === presentationDate.getMonth() + 1).abbr
      pres.fullDate = visit.presentation_date
      pres.link = link
      pres.meeting = visit.mtg_key
    },
  },
  computed: {
    ...mapGetters({
      facilityDetailPDFUrl: 'card/facilityDetailPDFUrl',
      iCanSee: 'user/iCanSee',
      lastFacilityRestockingVisit: 'card/getLastFacilityRestockingVisit',
      lastVideoList: 'card/getLastVideoList',
      monthNames: 'card/monthNames',
      prefCulture: 'user/userPreferredCulture',
      presentations_widget: 'churchMinistry/presentations_widget',
      profileSummary: 'card/getFacilitySummary',
      userIndKey: 'user/userId',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
    }),
    cityStateZipDisplay() {
      const { city, state_postal_code } = this.profileSummary
      if (city && state_postal_code) {
        return `${city}, ${state_postal_code}`
      }
      return city || state_postal_code || ''
    },
    facilityAddress() {
      let address = ''
      if (!!this.profileSummary) {
        address =
          this.profileSummary.address_line_1 +
          ' ' +
          (this.profileSummary.address_line_2 || '') +
          ' ' +
          (this.profileSummary.address_line_3 || '')
      }
      return address
    },
  },
  async created() {
    this.showEvents = false
    if (!this.userSelectedFacilityKey) {
      this.$router.push({ name: 'gideon-card' })
      return
    }
    this.setLoadingStatus(true)
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('event', 'quick-jump-gideon-cards'),
      this.getComponentTranslations('common.individual-relationship-type', 'common.placement-facility-types'),
    ]).then((results) => {
      this.stripReadableText(results[2], 'text')
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.pageLoad()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.col-3.date {
  min-height: 118px;
}

.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}

.church_image {
  min-width: 251px;
  min-height: 185px;
}

.profile_image {
  max-width: 285px;
  max-height: 185px;
  object-fit: contain;
}

.details {
  margin-bottom: 46px;
  padding: 32px 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);

  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;

      >* {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.primary {
  margin-bottom: 35px;

  >.col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  h5 {
    margin: 0;
    color: #1e68fb;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  strong {
    font-weight: 800;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  img {
    margin-top: 17px;
  }

  a {
    color: rgba(30, 104, 251, 1);
    text-decoration: underline;

    &:hover {
      color: rgba(30, 104, 251, 0.8);
    }
  }

  .btn {
    color: #fff;
    text-decoration: none;
  }

  img {
    margin: 0;
  }

  .specs {
    p {
      margin-bottom: 10px;
      line-height: 22px;

      &:last-of-type {
        margin-bottom: 17px;
      }
    }
  }

  .contact {
    strong {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.events {
  margin-bottom: 50px;

  .col-4 {
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;

      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }

  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

.actions {
  @include breakpoint(sm) {
    padding: 0 1rem;
  }
}

.card-deck {
  .card {
    svg {
      display: block;
      margin: 0 auto 13px;
    }
  }
}

.card-deck.row {
  margin-bottom: 30px;
}
</style>
